<template>
  <!-- Multiple Choice Submission -->
  <div>
    <!-- Multiple Choice Submission Card Header -->
    <b-card
      bg-variant="transparent"
      class="material-card border-light-secondary mb-1"
    >
      <div class="d-flex">
        <div class="mr-1">
          <b-avatar
            rounded="lg"
            variant="light-success"
          >
            <i class="card-icon ti ti-list-check" />
          </b-avatar>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <span class="text-muted">Soal Pilihan Ganda </span>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <!-- Multiple Choice -->
    <b-card
      v-for="(question, index) in questions"
      :key="index"
      bg-variant="transparent"
      class="material-card border-light-secondary p-1 mb-1"
    >
      <b-row>
        <b-col md="12">
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Pertanyaan
            </h6>

            <!-- Exam Question -->
            <div class="d-flex mb-1">
              <b-avatar
                class="small-gap-right"
                variant="primary"
              >
                {{ question.number }}
              </b-avatar>
              <p v-html="question.question" class="w-100"></p>
            </div>

            <!-- Exam Answer -->
            <div class="exam-answer-options">
              <div
                v-for="(option, index) in question.options"
                :key="index"
                class="d-flex"
              >
                <b-form-group class="w-100">
                  <b-input-group class="small-gap-bottom">
                    <b-input-group-prepend is-text>
                      <b-form-radio
                        v-model="question.correct_answer"
                        :name="'answer_' + question.number"
                        :value="option.key"
                        disabled
                      ><strong class="small-gap-left">{{
                        option.key | upperCase
                      }}</strong></b-form-radio>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="option.text"
                      readonly
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      bg-variant="transparent"
      class="material-card border-light-secondary p-1 mb-1"
    >
      <b-row>
        <b-col md="12">
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Pertanyaan
            </h6>

            <!-- Exam Question -->
            <div class="d-flex mb-1">
              <b-avatar
                class="small-gap-right"
                variant="primary"
              >
                <b-form-input
                  v-model="question.number"
                  class="p-0 text-center"
                  :state="errors.number ? false : null"
                  placeholder="no"
                />
              </b-avatar>
              <!-- <p class="font-weight-normal">
                  {{ quiz.question }}
                </p> -->
              <b-form-group
                class="w-100"
                :invalid-feedback="errors.question ? errors.question[0] : ''"
                :state="errors.question ? false : null"
              >
                <quill-editor v-model="question.question" />
              </b-form-group>
            </div>

            <!-- Exam Answer -->
            <div class="exam-answer-options">
              <div
                v-for="(option, index) in question.options"
                :key="index"
                class="d-flex"
              >
                <b-form-group
                  class="w-100"
                  :invalid-feedback="
                    errors[option.key]
                      ? errors[option.key][0]
                      : errors.correct_answer
                        ? errors.correct_answer[0]
                        : ''
                  "
                  :state="
                    errors[option.key]
                      ? false
                      : errors.correct_answer
                        ? false
                        : null
                  "
                >
                  <b-input-group class="small-gap-bottom">
                    <b-input-group-prepend is-text>
                      <b-form-radio
                        v-model="question.correct_answer"
                        name="answer"
                        :value="option.key"
                        :state="
                          errors[option.key]
                            ? false
                            : errors.correct_answer
                              ? false
                              : null
                        "
                      >
                        <strong class="small-gap-left">{{
                          option.key | upperCase
                        }}</strong>
                      </b-form-radio>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="option.text"
                      :state="
                        errors[option.key]
                          ? false
                          : errors.correct_answer
                            ? false
                            : null
                      "
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end">
        <b-button
          variant="outline-primary"
          class="ml-1"
          @click="submitForm"
        >
          <b-spinner
            v-if="componentLoading"
            class="mr-1"
            variant="light"
            small
          />
          <i
            v-if="!componentLoading"
            class="align-middle ti ti-device-floppy small-gap-right"
          />
          <span class="align-middle">Simpan Soal</span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BInputGroupPrepend,
  BAvatar,
  BMedia,
  BProgress,
  BFormGroup,
  BSpinner,
  BInputGroupAppend,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  reqGetTaskOptionQuestions,
  reqCreateTaskOptionQuestion,
} from '@/api/admin/taskOptionQuestion'
import quizExample from './quizExample'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BInputGroupPrepend,
    BInputGroupAppend,
    BAvatar,
    BMedia,
    BProgress,
    BFormGroup,
    BSpinner,
    BFormInvalidFeedback,
    quillEditor
  },
  filters: {
    upperCase(value) {
      return value.toUpperCase()
    },
  },
  data() {
    return {
      // state
      isLoading: true,
      componentLoading: false,

      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskData: {},
      // Quiz Data
      allQuiz: [],
      question: {
        number: 1,
        question: '',
        options: [
          {
            key: 'a',
            text: '',
          },
          {
            key: 'b',
            text: '',
          },
          {
            key: 'c',
            text: '',
          },
          {
            key: 'd',
            text: '',
          },
          {
            key: 'e',
            text: '',
          },
        ],
        correct_answer: '',
      },
      questions: [],
      errors: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    to.meta.breadcrumb[1].params = {
      trainingId: to.params.trainingId,
      chapterId: to.params.chapterId,
    }
    next()
  },
  created() {
    this.loadQuestions()
    this.allQuiz = quizExample
    this.taskData = JSON.parse(localStorage.getItem('taskData'))
  },
  methods: {
    loadQuestions() {
      reqGetTaskOptionQuestions(this.$route.params.taskId).then(response => {
        this.questions = response.data.data.map(question => ({
          id: question.id,
          number: question.number,
          question: question.question,
          options: [
            {
              key: 'a',
              text: question.a,
            },
            {
              key: 'b',
              text: question.b,
            },
            {
              key: 'c',
              text: question.c,
            },
            {
              key: 'd',
              text: question.d,
            },
            {
              key: 'e',
              text: question.e,
            },
          ],
          correct_answer: question.correct_answer,
        }))
        this.question.number = this.questions.length + 1 || 1
      })
    },
    pushQuestion(question) {
      this.questions.push({
        id: question.id,
        number: question.number,
        question: question.question,
        options: [
          {
            key: 'a',
            text: question.a,
          },
          {
            key: 'b',
            text: question.b,
          },
          {
            key: 'c',
            text: question.c,
          },
          {
            key: 'd',
            text: question.d,
          },
          {
            key: 'e',
            text: question.e,
          },
        ],
        correct_answer: question.correct_answer,
      })
    },
    resetQuestion() {
      this.question = this.questionTemplate
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    async submitForm() {
      const data = {
        number: this.question.number,
        question: this.question.question,
        a: this.question.options[0].text,
        b: this.question.options[1].text,
        c: this.question.options[2].text,
        d: this.question.options[3].text,
        e: this.question.options[4].text,
        correct_answer: this.question.correct_answer,
      }

      this.componentLoading = true
      await reqCreateTaskOptionQuestion(this.$route.params.taskId, data)
        .then(response => {
          // show success toast
          this.showToast(
            'success',
            'Check',
            'Berhasil',
            'Soal berhasil ditambah',
          )

          this.componentLoading = false

          this.pushQuestion(response.data.data)
          this.question = {
            number: this.question.number = response.data.data.number + 1,
            question: '',
            options: [
              {
                key: 'a',
                text: '',
              },
              {
                key: 'b',
                text: '',
              },
              {
                key: 'c',
                text: '',
              },
              {
                key: 'd',
                text: '',
              },
              {
                key: 'e',
                text: '',
              },
            ],
            correct_answer: '',
          }
        })
        .catch(error => {
          // show failed toast
          this.showToast(
            'danger',
            'Check',
            'Gagal',
            error.response.data.message || 'Soal gagal ditambah',
          )
          this.componentLoading = false
          this.errors = error.response.data.errors
          console.log(error)
        })
    },
  },
}
</script>

  <style lang="scss" scoped>
.extra-small-gap-bottom {
  margin-bottom: 4px;
}

.small-gap-left {
  margin-left: 8px;
}
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.round-button {
  padding: 0.2%;
  margin: 0.7%;
  font-size: 0.7rem;
  height: 2.5rem;
  width: 2.5rem;
}

.w-30 {
  width: 30%;
}

.exam-answer-options .form-control[readonly] {
  cursor: pointer;
  background-color: white !important;
}

.exam-answer-options .delete-button {
  height: 2.714rem;
}
</style>
