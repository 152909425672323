import axios from '@/libs/axios'

export function reqGetTaskOptionQuestions(taskId, payload) {
  return axios.get(`api/admin/tasks/${taskId}/question-options`, { params: payload })
}
export function reqGetTaskOptionQuestion(id) {
  return axios.get(`api/admin/question-options/${id}`)
}
export function reqCreateTaskOptionQuestion(taskId, payload) {
  return axios.post(`api/admin/tasks/${taskId}/question-options`, payload)
}
export function reqUpdateTaskOptionQuestion(id, payload) {
  return axios.post(`api/admin/question-options/${id}?_method=PATCH`, payload)
}
export function reqDeleteTaskOptionQuestion(id) {
  return axios.delete(`api/admin/question-options/${id}`)
}
